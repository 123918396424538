<template>
  <div class="app">
    <div class="admin_main_block">
      <div class="admin_breadcrumb">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/Admin/index' }"
          >首页
          </el-breadcrumb-item
          >
          <el-breadcrumb-item>商家联盟</el-breadcrumb-item>
          <el-breadcrumb-item>门店信息</el-breadcrumb-item>
          <el-breadcrumb-item>会员卡修改</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <el-form ref="form" :model="form">
      <div class="admin_main_block admin_m15">
        <el-row :gutter="40">
          <el-col :span="6">
            <el-form-item label="门店名称">
              <el-input placeholder="门店名称" v-model="form.shopName" disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="会员卡名称">
              <el-input placeholder="会员卡名称" v-model="form.name" disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="会员卡面值">
              <el-input placeholder="会员卡面值" v-model="form.price" disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="售卖数量">
              <el-input placeholder="售卖数量" v-model="form.saleAmount" disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="6">
            <el-form-item label="让利比例">
              <el-input placeholder="让利比例" v-model="form.givingPercent"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="6">
            <el-button icon="el-icon-save" type="primary" @click="saveShopCard">保存</el-button>
            <el-button icon="el-icon-back" type="primary" @click="backShopCard">返回</el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      form: {
        id: this.$route.query.card.id,
        shopName: this.$route.query.card.shopName,
        name: this.$route.query.card.name,
        price: this.$route.query.card.price,
        saleAmount: this.$route.query.card.saleAmount,
        givingPercent: this.$route.query.card.givingPercent,
      },
    };
  },
  created() {
  },
  methods: {
    saveShopCard() {
      if (!this.form.givingPercent) {
        this.$message.error("请输入让利比例");
        return;
      }
      this.$post(this.$api.systemShopFixvalueCardUpdate, {
        id: this.form.id,
        givingPercent: this.form.givingPercent
      }).then((res) => {
        console.log(res);
        if (res.code == 1) {
          this.$message.success("保存成功");
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    backShopCard() {
      this.$router.push({name: 'cardList', query: {currentPage: this.$route.query.currentPage}})
    },
  }
}
</script>